@media print {
	/* Estilos para medios impresos */
	.footerInforme{
		background-color: #dbdbdb; 
		padding: 5px;
	}
  }

.footerInforme{
	background-color: #dbdbdb; 
	padding: 5px;
}

.logo{
	width: 100%;
	padding: 10px 0px;
}

.logoPdf{
	width: 150px;
	padding: 10px 0px;
}

.contenedor{
 	padding: 0px 20px;
}

.barra_izquierda{
	height: 800px;
	background-color: #fff;
	justify-content: center;
	padding: 0px 0px 0px 10px !important;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.barra_derecha{
	height: 100%;
}

.cont_img_perfil{
	height: 60px;
	width: 60px;
	border-radius: 100px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	padding: 0px 8px;
	float: left;
	overflow: hidden;
}

.avatar{
	margin-top: 5px;
	height: 50px;
	width: 50px;
	border-radius: 100px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.cont_datos_usuario{
	padding: 5px 0px 5px 10px;
}

.cont_datos_usuario h5 {margin: 0px; font-size: 16px; font-weight: 400;}
.cont_datos_usuario p {font-size: 13px; margin: 0px;}

.contenedor_datos_usuario{
	padding: 10px 0px 20px 30px !important;
	border-bottom: 1px solid #eee;
}

.linea{border-bottom: 1px solid #eee;}

.item{
	width: 100%;
	border: none;
	padding: 8px 15px;
	background-color: transparent;
	text-align: left;
	border-bottom: 1px solid #eee;
	color: #333;
}

.sub-item{
	width: 100%;
	border: none;
	padding: 8px 0px 8px 30px;
	background-color: transparent;
	text-align: left;
	border-bottom: 1px solid #eee;
	color: #333;
}

.item:hover, .sub-item:hover{
	background-color: #1874f0;
	color: #fff;
}

.active{
	background-color: #1874f0;
	color: #fff;
}

.boton_estandar{
	background-color: #1d65f4 !important;
	border-radius: 25px;
	background: rgb(29,101,244);
	background: linear-gradient(36deg, rgba(29,101,244,1) 0%, rgba(21,74,180,1) 100%);
	color: #fff;
	padding: 14px 20px !important;
}

.boton_estandar_blanco{
	border-radius: 25px;
	background-color: #fff;
	color: #1d65f4;
	padding: 14px 16px !important;
	max-height: 60px !important;
}

.boton_estandar:hover{
	opacity: 0.8;
	color: #fff;
}

.boton_estandar_blanco:hover{
	opacity: 0.8;
	color: #1d65f4;
}

.contenedorAdmin{
	background-color: rgba(255,255,255,0.9);
	border-radius: 20px;
	margin-top: 20px;
	padding-bottom: 20px;
	margin-right: 20px;
	overflow-y: scroll !important;
	height: 96%;
}

.SinPadding{
	padding: 0px !important;
	z-index: 1;
}

.NombreIgleCom{
	padding: 0px 0px 0px 15px;
}

.tabla_funciones{
	padding: 0px !important;
	width: 160px !important;
}

.tabla_unica{
	padding: 0px !important;
	width: 80px !important;
}

.is-invalid{
	border: 2px solid red !important;
}

.selectAtri{
	width: 100% !important;
}

.selectEstado{
	width: 100% !important;
	margin-top: 5px;
}

.error{
	font-size: 15px !important;
	color: red;
}

.cont_img_perfil img{
	width: 135%;
	height: 100%;
	margin-left: -7px;
}

.ImgProducto{
	width: 25px;
	height: 25px;
	background-color: lightgray;
	border-radius: 5px;
}

.Logoinforme{
	width: 65px;
	height: 65px;

}

.ImgProductoSin{
	width: 25px;
	height: 25px;
	background-color: rgb(255, 165, 165);
	border-radius: 5px;
}

.img_user img{
	margin-top: -4px;
	width: 30px;
	height: 30px;
	background-color: lightgray;
	border-radius: 30px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Subir" !important;
}

.FotoPrev{
	width: 100%;
	height: 164px;
	background-color: #f2f2f2;
	margin-top: 23px;
}

.FotoPrev img, .ImgProducto img{
  width: 100%;
  height: auto;
  border-radius: 5px;
}
@supports(object-fit: cover){
    .FotoPrev img, .ImgProducto img, .fotoPedido img{
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}

.campos_atributos{
	margin: 5px 0px !important;
}

.radio_atributo input{
	height: 15px;
    width: 15px;
    margin: 0px 5px 0px 0px;
}

.radio_atributo label{
	margin-bottom: .1rem !important;
}

.combinacion {
	width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    padding: 15px;
    background-color: #fff;
}

.combinacion h6 {
	margin-bottom: 3px;
	font-weight: bold;
}

.combinacion p {
	font-size: 13px;
	margin-bottom: 0px !important;
}

.receta{
    padding: 10px 15px 5px 15px !important;
}

.bola_estado_activo{
	height: 12px;
	width: 12px;
	background-color: #50bd50;
	border-radius: 30px;
}

.bola_estado_desact{
	height: 12px;
	width: 12px;
	background-color: #b7b7b7;
	border-radius: 30px;
}

.tabla_productos td, .tabla_productos th {
	padding: 15px 5px;
}

.estadisticas{
	height: 400px !important;
	padding: 10px;
}

.campoClient{
	background-color: #fff !important;
	height: 45px !important;
}

.fotoPedido{
	height: 110px;
	width: 110px;
	background-color: lightgray;
}

.pedidoAdmin{
	margin-top: 20px;
}

.tachado{
	text-decoration:line-through;
	color: #999;
}

.CajaInforme{
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme1{
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	border-left: #27ae60 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme2{
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	border-left: #f1c40f solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme3{
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
	border-left: #c0392b solid 5px;
	padding: 15px 3px 5px 15px;
}


.CajaInforme:hover{
	background-color: #ec6c49;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme1:hover{
	background-color: #27ae60;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme2:hover{
	background-color: #f1c40f;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme3:hover{
	background-color: #c0392b;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme h4, .CajaInforme1 h4, .CajaInforme2 h4, .CajaInforme3 h4, .CajaInforme1 h3 {margin: 0px;}

.CajaInformeUnico{
	width: 95.5%;
	height: 80px;
	margin: 10px 10px;
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInformeMenor{
	width: 95.5%;
	height: 50px;
	margin: 5px 10px;
	border: 1px solid lightgray;
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.derecha{
	float: right;
	margin-right: 10px;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
	background-color: red !important;
	border: none !important;
	outline-color: none;
	font-weight: bold;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
	background-color: red !important;
	border: none !important;
	outline-color: none;
	font-weight: bold;

}

.busqueda{
	margin-left: -30px;
}

.titulo_fecha{
	font-size: 20px;
	margin-left: 10px;
}

.botones_escritorio{
	padding: 10px 0px;
}

.botones_escritorio div{
	padding: 10px;
}

.SelectFiltro{
	border-radius: 30px;
	height: 46px;
    border: none;
    padding: 5px 10px !important;
    background-color: #fff;
    font-size: 14px;	
    color: red;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.filtroPedidos{
	margin-top: 15px;
}

.datosConfig p{
	margin-bottom: 3px !important;
}

.estados{
	padding: 5px 10px;
	border-radius: 5px;
}

.Rojo{background-color: #eb8b8d;}
.textRojo{color: #ac4345; font-weight: bold;}
.Azul{background-color: #88d3e2;}
.Verde{background-color: #65c895;}
.Gris{background-color: #c1c1c1;}
.Blanco{background-color: #ffffff; border: 1px solid #eee;}
.Amarillo{background-color: #ffdba7;}
.textVerde{color: #368d60; font-weight: bold;}

.sinMargen div{
	margin-top: 0px !important;
}

.btnBuscar {
	float: right;
    width: 190px;
    height: 40px;
    margin: 5px 5px 0px 0px !important;
    background-color: #fff !important;
    color: #333;
    font-size: 15px;
}

.coment{
	font-size: 13px;
	color: #858585;
}

.buscarZonas{
	margin-top: -15px;
}

.selectores{
	margin-top: 10px;
}

.impresionPdf{
	padding: 30px 30px 60px 30px;
}

.tabla td, .tabla th {
	font-size: 12px !important;
}

.cuadroBusqueda {
	width: auto;
	height: auto;
	max-height: 200px;
	overflow-y: scroll;
	background-color: #fff;
	position: relative;
	top: 1px;
	left: 1px;
	padding: 10px 10px 0px 10px;
}

.ProductMin {
	display: flex;
	background-color: #fff;
	border: none;
	width: 100%;
}

.cuadroBusqueda hr {
	margin: 7px 0px 7px 0px;
}

.fotoProdMin {
	height: 50px;
	width: 50px;
	background-color: lightgray;
}

.textProdMin {
	flex-direction: 'column';
	padding-left: 10px;
}

.textProdMin h6 {
	font-weight: bold;
	margin: 5px 0px 0px 0px;
	text-align: left;
}

.textProdMin p {
	font-size: 15px;
	text-align: left;
	margin: 0px;
}

.activo {
	display: block;
}

.hidden {
	display: none;
}



.selectIglesia{
		display: inline-block;
		width: 100%;
		margin-top: 5px;
		height: calc(1.2em + 0.75rem + 2px);
		padding: 0.275rem 1.75rem 0.275rem 0.50rem;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		vertical-align: middle;
		background: #fff url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e) no-repeat right 0.75rem center/8px 10px;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		appearance: none;
}

@media print {
	.pagebreak {
		page-break-before: always;
	  }	
	@page { size: auto !important; }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
	.barra_izquierda{
		height: auto !important;
	}
	html{
		overflow-y: scroll;
	}
}

html{
	overflow-y: scroll;
}



  
